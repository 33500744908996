
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        
































































































































































































































































.history {
  margin: 2.5rem 0 1.5rem;
}
